// Dark Theme Color //

$black: #07080b; // mainly used by main background
$jet: #0c0f13; // mainly used by paper
$carbon: #141820; // mainly used by clickable components that is placed on darker background
$charcoal: #1a1f2a; // mainly used by the components in the paper
$grey: #54617b; // mainly used by clickable components

$lightFontDark: #c2c2c2;
$mediumFontDark: #989898;
$darkFontDark: #515860;

$white: #ffffff;

$borderCharcoal: #1a1f2a;
$borderGrey: #54617b;

// Light Theme Color //

$pureWhite: #ffffff; // mainly used by main background and paper
$cream: #e4e6eb; // mainly used by the components in the paper
$darkGrey: #707070;

$lightFontLight: #707070;
$mediumFontLight: #3a3a3a;
$darkFontLight: #292929;

$borderLight: #bdc0c2;

// General Color //

$pink: #ff0088;
$comment: #3677ef;
$spread: #02c255;
$blue: rgba(54, 119, 239, 1);
$blueTransparent: rgba(54, 119, 239, 0.2);
$xtm: #7e49ec;
$pureWhiteTransparent: rgba(255, 255, 255, 0.3);
$greyTransparent: rgba(84, 97, 123, 0.2);
$darkGreyTransparent: rgba(112, 112, 112, 0.2);

$mainGradient: linear-gradient(to right, #9a00ff, #00b1ff);
$subGradient: linear-gradient(to right, #ff0060, #315aff);
$tertGradient: linear-gradient(to right, #005dff 0%, #00c23a 100%);

$editProfileGradient: linear-gradient(to left, #08f, #2000ff);

// ========================== COLORS FROM TORUM.COM  =============================== //

/* ------ GENERAL COLOR RULES ------ */

/* General Background */

$backgroundColorLight: #e6ecf0;
$backgroundColorDark: #0d0d0d;

$backgroundHoverColorLight: #e6ecf0;
$backgroundHoverColorDark: #e6ecf0;

/* General Papers */

$primaryPaperLight: #ffffff;
$primaryPaperDark: #15181c;

$secondaryPaperLight: #f2f2f2;
$secondaryPaperDark: #202226;

$primaryPaperBorderLight: #bdc0c2;
$primaryPaperBorderDark: #15181c;

$secondaryPaperBorderLight: #d7d9da;
$secondaryPaperBorderDark: #202226;

$primaryPaperInternalLinesLight: #d7d9da;
$primaryPaperInternalLinesDark: #0d0d0d;

$secondaryPaperInternalLinesLight: #d7d9da;
$secondaryPaperInternalLinesDark: #15181c;

$inputBackgroundLight: #ffffff;
$inputBackgroundDark: #202226;

/* General Fonts */

$primaryFontsLight: #292929;
$primaryFontsDark: #c2c2c2;

$secondaryFontsLight: #707070;
$secondaryFontsDark: #515860;

$tertiaryFontsLight: #989898;
$tertiaryFontsDark: #707070;

$descriptionFontsLight: #707070;
$descriptionFontsDark: #989898;

$highlightedBlueFonts: #3677ef;
$highlightedBrighterBlueFonts: rgb(92, 175, 243);
$ekvaFonts: #ff6536;
$dateFonts: #26b99a;
$expFonts: #ffaa00;

$descriptionFontsLight: #3a3a3a;
$headingFontsDark: #d6d6d6;

$shardFonts: #e65eb0;
$dustFonts: #ff9a36;
$warning-green-fonts: #00ad03;
$warning-red-fonts: #ff0023;
$seed-color: #ff6c00;
$early-color: #1bff17;
$early--value-color-dark: #989898;
$early--value-color-light: #707070;
$deposit-color: #200061;
$warning-color: #ed1c24;

/* General Icons */

$iconsReactionInactiveLight: #989898;
$iconsReactionInactiveDark: #515860;

$iconsReactionActiveLight: linear-gradient(to right, #4b57eb, #2196f3);
$iconsReactionActiveDark: linear-gradient(to right, #4b57eb, #2196f3);

/* General Buttons */

$solidButtonHoverBackgroundColor: #5a8ff2;
$solidButtonDisabledBackgroundColorLight: #bfbfbf;
$solidButtonDisabledBackgroundColorDark: #2c323a;

$solidButtonDisabledFontColorLight: #ffffff;
$solidButtonDisabledFontColorDark: #707070;

$outlinedButtonHoverBackgroundColor: rgb(235, 238, 255);
$outlinedButtonDisabledBackgroundColor: #bfbfbf;

$emptyButtonHoverBackgroundColorLight: rgb(235, 235, 235);
$emptyButtonHoverBackgroundColorDark: rgb(56, 56, 56);

$greyButtonHoverBackgroundColorLight: rgb(218, 218, 218);
$greyButtonHoverBackgroundColorDark: rgb(80, 80, 80);

/* Scroll Bar */
$scrollBarTrackBorderLight: rgb(180, 180, 180);
$scrollBarTrackBorderDark: rgb(180, 180, 180);

$scrollBarTrackBackgroundLight: rgb(235, 235, 235);
$scrollBarTrackBackgroundDark: rgb(31, 31, 31);

$scrollBarThumbBorderLight: #bdbdbd;
$scrollBarThumbBorderDark: rgb(56, 56, 56);

$scrollBarThumbBackgroundLight: rgb(193, 193, 193);
$scrollBarThumbBackgroundDark: rgb(56, 56, 56);

/* Paper Box Shadow */
$paperBoxShadowLight: 0 3px 6px 0 rgba(0, 0, 0, 0.17);
$paperBoxShadowDark: 0 3px 6px 0 rgba(0, 0, 0, 0.17);

/* Selected Tab Border Color */
$selectedTabBorderColor: #3677ef;
$selectedFilterLeftOutlineColor: linear-gradient(176deg, #4b57eb, #2196f3);

/* Filter Drawer Background Color */
$filterDrawerHoverBackgroundLight: rgba(255, 255, 255, 0.3);
$filterDrawerActiveBackgroundLight: rgba(255, 255, 255, 0.8);
$filterDrawerHoverBackgroundDark: rgba(32, 34, 38, 0.3);
$filterDrawerActiveBackgroundDark: rgba(32, 34, 38, 0.8);

/* General Background Hover Color */
$hoverBackgroundLight: #e6ecf0;
$hoverBackgroundDark: rgba(32, 34, 38, 0.3);

/* General Text Input Color */
$feeTextColor: rgb(126, 73, 236);
$textInputBorderColorInactiveLight: #989898;
$textInputBorderColorOnHoverLight: #838383;
$textInputBorderColorOnFocusLight: #989898;
$textInputBorderColorInactiveDark: #202226;

$textInputTextColorInactiveLight: #989898;
$textInputTextColorInactiveDark: #515860;

$textInputBackgroundColorInactiveDark: #202226;

/* __________________________________________________________________ */
/* ------ EXCEPTION: COLOR RULES FOR DIFFERENT MODULES ------ */

/* ___________ (I) Home Module ___________ */

/* (1): Icons and Texts for Home Page Post Type Filter Tab */
$homePostTypeFilterTabActiveLight: linear-gradient(to right, #4b57eb, #2196f3);
$homePostTypeFilterTabActiveDark: linear-gradient(to right, #4b57eb, #2196f3);
$homePostTypeFilterTabInactiveLight: #989898;
$homePostTypeFilterTabInactiveDark: #515860;

$homeResharePostBackgroundLight: #f2f2f2;
$homeResharePostBackgroundDark: #202226;

$searchResultHoverBackgroundLight: rgba(54, 119, 239, 0.2);
$searchResultHoverBackgroundDark: rgba(54, 119, 239, 0.2);

/* ___________ (II) User Profile Module ___________ */

/* (1): Text for Mutual Interest Tabs and User Profile Post Type Filter Tab */

$userProfileTextTabsActiveLight: #3677ef;
$userProfileTextTabsActiveDark: #3677ef;
$userProfileTextTabsInactiveLight: #989898;
$userProfileTextTabsInactiveDark: #515860;

/* ___________ (III) Company Module ___________ */

/* (1): Text for Company Detail Tabs and Company Profile Post Type Filter Tab */

$companyTextTabsActiveLight: #3677ef;
$companyTextTabsActiveDark: #3677ef;
$companyTextTabsInactiveLight: #989898;
$companyTextTabsInactiveDark: #515860;

/* (2): Text for Company Details & Description */

$companyTextDetailsLight: #292929;
$companyTextDetailsDark: #989898;

/* ___________ (IV) Clan Module ___________ */

/* (1): Text for Clan Thread Contents. NOTE: This rule applies to all clan threads, including the ones shared on HOME, USER PROFILE and COMPANY PROFILE */

$clanTextThreadContentLight: #292929;
$clanTextThreadContentDark: #989898;

/* (2): Background Color for Exp Bar */
$expBarBackgroundLight: #d7d9da;
$expBarBackgroundDark: #515860;
$expBarFilledBackground: linear-gradient(to right, #ffa900, #fff700);

/* ___________ (V) Messenger Module ___________ */

/* (1): Messenger Title Container & Receiver Info Title Container */

$messengerTitleContainerLight: #ffffff;
$messengerTitleContainerDark: #0d0d0d;

/* (2): Messenger Content Container */

$messengerContentContainerLight: #ffffff;
$messengerContentContainerDark: #15181c;

/* (3): Messenger Left Panel Item Container */

$messengerItemContainerActiveLight: #e6ecf0;
$messengerItemContainerInactiveLight: #ffffff;
$messengerItemContainerActiveDark: #202226;
$messengerItemContainerInactiveDark: #15181c;

/* (4): Icons on Messenger */

$messengerIconLight: #989898;
$messengerIconDark: #515860;

/* (5): Search Box and Text Box Container (Input Container) */

$messengerInputContainerLight: #e6ecf0;
$messengerInputContainerDark: #202226;

/* (6): Conversation Bubble Background Color */

$messengerRecipientBubbleLight: #e6ecf0;
$messengerRecipientBubbleDark: #202226;
$messengerSenderBubbleLight: #3677ef;
$messengerSenderBubbleDark: #3677ef;

/* (7): Paper Box Shadow */
$messengerPaperBoxShadowLight: 3px 0 4px 0 rgba(0, 0, 0, 0.17);
$messengerPaperBoxShadowDark: 5px 0 4px 0 rgba(0, 0, 0, 0.17);

/* ___________ (VI) Wallet Module ___________ */

/* (1): Icons on Wallet Left Panel's Tab */
$walletIconDrawerTabInactiveLight: #989898;
$walletIconDrawerTabInactiveDark: #515860;

/* (2): Texts on Wallet Left Panel's Tab */

$walletTextDrawerTabActiveLight: #3677ef;
$walletTextDrawerTabInactiveLight: #989898;
$walletTextDrawerTabActiveDark: #3677ef;
$walletTextDrawerTabInactiveDark: #515860;

/* (3): Top-outline for Wallet Right Panel's Card */
$walletCardTopOutline: linear-gradient(to right, #4b57eb, #2196f3);

/* (4): Text on Wallet Right Panel */
$walletXTMTextColor: #7e49ec;
$walletBalanceTextColor: #989898;
$walletDescriptionLight: #707070;
$walletDescriptionDark: #989898;
$walletDescriptionHighlightedFont: #3677ef;
$walletTransactionNameColor: #dfa125;

$walletClaimProgressBarBackground: rgba(126, 73, 236, 0.4);

/* ___________ (VII) Content Module (Posts or Threads that appears on HOME, USER PROFILE, COMPANY, and THREAD)___________ */

/* (1): Placeholder for Create Post's and Comment Text Box */
$placeholderTextBoxActiveLight: rgba(152, 152, 152, 0.7);
$placeholderTextBoxActiveDark: rgba(81, 88, 96, 0.7);
$placeholderTextBoxInactiveLight: rgba(152, 152, 152, 1);
$placeholderTextBoxInactiveDark: rgba(81, 88, 96, 1);

/* (2): Top outline for Create Post Box */
$createPostBoxTopOutline: linear-gradient(to right, #4b57eb, #2196f3);

/* (3): Icons for Comment on Post's Text Box */
$iconListCommentTextBoxActiveLight: linear-gradient(to right, #4b57eb, #2196f3);
$iconListCommentTextBoxActiveDark: linear-gradient(to right, #4b57eb, #2196f3);
$iconListCommentTextBoxInactiveLight: #989898;
$iconListCommentTextBoxInactiveDark: #515860;

/* (4): Three Dot Icon as Post Options ASKKKKK */
$postOptionsInactiveLight: #989898;
$postOptionsInactiveDark: #707070;

/* (5): User Description on any Content */
$textUserDescriptionLight: #707070;
$textUserDescriptionDark: #989898;
$hyperlinkHover: #719ff4;

/* (6): Button for Create Post Box */
$iconHoverBackgroundColorLight: rgba(54, 119, 239, 0.2);
$iconHoverBackgroundColorDark: rgba(54, 119, 239, 0.2);
$iconSelectedBackgroundColorLight: rgba(54, 119, 239, 0.35);
$iconSelectedBackgroundColorDark: rgba(54, 119, 239, 0.1);

/* (7): Reactions Font Color */
$loveReactionFontColor: #e52e2e;
$commentReactionFontColor: #3677ef;
$tipReactionFontColor: #7e49ec;
$shareReactionFontColor: #17bf63;
$repostReactionFontColor: #ea552d;

/* (8): Background Color for Reactions Icon On Hover */
$loveIconBackgroundColor: rgba(229, 46, 46, 0.1);
$commentIconBackgroundColor: rgba(54, 119, 239, 0.1);
$tipIconBackgroundColor: rgba(126, 73, 236, 0.1);
$shareIconBackgroundColor: rgba(33, 150, 243, 0.1);
$repostIconBackgroundColor: rgba(234, 85, 45, 0.1);

/* (9): Color for More Icon */
$moreIconLight: #989898;
$moreIconDark: #515860;
$moreIconActive: #3677ef;
$moreIconHoverBackgroundLight: rgba(54, 119, 239, 0.2);
$moreIconHoverBackgroundDark: rgba(54, 119, 239, 0.2);

/* (10): Thread Ladder Line */
$threadLadderLineLight: #d7d9da;
$threadLadderLineDark: #202226;

/* (11): Post Thread */
$backToViewLight: #989898;
$backToViewDark: #515860;
$backToViewHover: #3677ef;
$postThreadDescriptionTextLight: #292929;
$postThreadDescriptionTextDark: #989898;
$editorToolbarBackgroundLight: #e6ecf0;
$editorToolbarBackgroundDark: #0d0d0d;
$editorToolbarBorderLight: #bdc0c2;
$editorToolbarBorderDark: #0d0d0d;
$editorOptionHoverLight: rgba(133, 174, 250, 0.3);
$editorOptionHoverDark: rgba(133, 174, 250, 0.1);
$editorInputBorderLight: #bdc0c2;
$editorInputBorderDark: #202226;
$editorInputBackgroundLight: #ffffff;
$editorInputBackgroundDark: #202226;

/* ___________ (VIII) Header Side Drawer Module ___________ */

/* (1): Background Color for NavItem On Hover */
$navItemHoverBackgroundLight: #e6ecf0;
$navItemHoverBackgroundDark: rgba(32, 34, 38, 0.3);

/* (2): Background Color for Active NavItem */
$navItemActiveBackgroundLight: #dfe7ec;
$navItemActiveBackgroundDark: rgba(32, 34, 38, 0.8);

/* (3): Text Color for NavItem */
$navItemTextActiveLight: #3677ef;
$navItemTextActiveLightFont: #ffffff;
$navItemTextInactiveLight: #989898;
$navItemTextActiveDark: #3677ef;
$navItemTextInactiveDark: #515860;

/* (4): Active LeftOutline for NavItem */
$navItemActiveLeftOutline: #4b57eb;

/* (5): Icon Color for NavItem */
$navItemIconInactiveLight: #989898;
$navItemIconInactiveDark: #515860;

/* ___________ (IX) ERROR COLOR ___________ */

/* (1): WARING COLOR */

$warningColorLight: #e52e2e;
$warningColorOnHoverLight: #e96666;

/* ___________ (X) SPECIAL COLOR ___________ */

$accessDeniedColorLight: #ffa100;
$accessDeniedColorDark: #ff7500;
