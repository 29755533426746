// @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600');
@import url('https://fonts.googleapis.com/css?family=Poppins:500,600,700,800');
@import 'src/assets/styles/colors.scss';

.light {
  body {
    background: $pureWhite;
  }
}

.dark {
  body {
    background: $black;
  }
}

body {
  margin: 0;
  overflow: auto;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Montserrat', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $black;
}

body::before {
  content: '';
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 0;
  width: 11%;
  z-index: -1;
  opacity: 0.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Montserrat', 'Courier New', monospace;
}

p {
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

i,
span {
  display: inline-block;
}
