.App {
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.wrong-network {
  color: white;
  font-sight: bold;
}

.wrong-network-modal-text {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}

.wrong-network-modal-container {
  width: 380px;
}

.wrong-network-modal-title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;

  text-align: center;
  color: #c2c2c2;
}
.wrong-network-modal-description {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: #989898;
  margin: 10px 0px 30px;
}

.wrong-network-modal-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
