// @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700');
@import 'src/assets/styles/colors.scss';

.light {
  body {
    background: $pureWhite;
  }
}

.dark {
  body {
    background: $black;
  }
}

body {
  font-family: 'Roboto' !important;
  overflow: auto;
}

body::before {
  content: '';
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  right: 0;
  width: 11%;
  z-index: -1;
  opacity: 0.5;
}

.btn {
  border-radius: 5rem;
  padding: 0.375rem 1rem;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  background: #000222;
}

.btn-default {
  background: linear-gradient(180deg, #666ccc 0%, #66cccc 100%);
  color: #fff;
  border-color: #212342;
}
.btn-primary:focus,
.btn-primary.focus {
  background-color: #212342;
  border: 1px solid #525366;
}
.btn-primary {
  position: relative;
  border: 1px solid #66c0cc;
  background: none;
  background-clip: padding-box;
}

.btn-primary:hover {
  color: #fff;
  background-color: #000222;
  border-color: #000222;
}
.nftmarketplace-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.search {
  font-size: 13px;
  height: 36px;
}
.btn {
  font-size: 14px;
}
.search:focus {
}
.text-muted.font-12 {
  font-size: 11px;
}
.text-muted.font-12 {
  font-size: 11px;
}
.pro-pic {
  max-width: 60px;
  object-fit: cover;
  margin-bottom: 1rem;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid #525366;
}

ul.CTAs {
  margin: 0;
}

.filter a {
  word-spacing: normal;
}
.filter img {
  filter: none !important;
}
.fixedhw {
  height: 16px !important;
  width: 16px !important;
}
/* ------------------right content */

.p-30 {
  padding: 30px;
}

.right-container {
  cursor: default;
}

.right-container hr {
  margin-left: -15px;
  margin-right: -15px;
}

#content {
  color: #fff;
  /* max-height: 100vh; */
  width: 100%;
}

.oy-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

// .ox-auto {

// }

.main-content {
  min-height: calc(100vh - 170px);
  // padding-bottom: 60px;
}

.right-top-browse h4 {
  font-family: 'Roboto', serif;
  white-space: nowrap;
}

.form-control.search {
  background: #000222;
  border-radius: 5rem;
  border: 0;
}

.search-top-box {
  background: #000222;
  border: 0;
}

.search-top-box:focus {
  border-radius: 1rem 1rem 0rem 0rem !important;
}

.light {
  .form-control {
    background: $cream;
    border-color: $cream;
    color: $darkFontLight;
    &::placeholder {
      color: $mediumFontDark;
    }
  }
}

.dark {
  .form-control {
    background: $carbon;
    border-color: $carbon;
    color: $lightFontDark;
    &::placeholder {
      color: $grey;
    }
  }
}

.form-control {
  border-radius: 10px;
  padding: 10px 12px;
}

.form-control:focus {
  // color: #fff;
  // background-color: #ffffff1c;
  border-color: #80bdff00;
  outline: 0;
  box-shadow: none;
}

.search-img {
  position: absolute;
  left: 1rem;
  margin: 0.3rem 0;
}

.sel {
  min-width: 93px;
  margin-right: 2em !important;
}
.topsellermargin {
  margin-right: 15px !important;
}
.left-p-3 {
  padding-left: 3rem !important;
}

.font-14 {
  font-size: 0.9rem;
  color: #66cccc;
}

.font-12 {
  font-size: 0.7rem;
}

.filter-tab button {
  color: #9598c2;
  border: 1px solid #525366;
  margin: 0.2rem;
}

button.select {
  color: #fff;
  border-color: #66c0cc;
}

.filter-tab .btn.select img {
  filter: invert(80%) sepia(58%) saturate(332%) hue-rotate(123deg) brightness(84%) contrast(90%);
}

// .card {
//   background-color: #000222;
//   background-clip: border-box;
//   border: 0px solid rgba(33, 35, 66, 1);
//   border-radius: 1.2rem;
//   /* overflow: hidden; */
//   margin-bottom: 15px !important;
//   min-width: 220px;
//   max-width: 220px;
//   position: relative;
//   box-shadow: -4px -4px 20px rgba(255, 255, 255, 0.02), 5px 5px 15px rgba(0, 0, 0, 0.24);
// }

.card {
  background-color: inherit;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

// @media (max-width: 1279px) {
//   .card-deck {
//     justify-content: center;
//   }
// }

.card-deck {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0;
}

@media (min-width: 576px) {
  .card-deck .card {
    min-height: 277px;
    flex: inherit;
    margin: 0;
  }
}
/* 6-mar */
.border-gradient-green {
  border-image-source: linear-gradient(to left, #00c853, #b2ff59);
}
/* heart animation */

#heart-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.twitter-heart {
  position: absolute;
  /* background: url(https://abs.twimg.com/a/1454637594/img/t1/web_heart_animation.png); */
  background-position: 0px;
  background-repeat: no-repeat;
  /* height: 100px; */
  /* width: 100px; */
  top: 0;
  left: 0;
  /* pointer-events: none; */
}

.toggle {
  color: #ff3232;
  opacity: 0;
  transform: scale(3);
  z-index: 2;
  position: relative;
}

.fas.fa-heart {
  display: none;
}

.toggle:checked ~ .fas.fa-heart {
  display: block;
  color: red;
}

.toggle:checked ~ .far.fa-heart {
  opacity: 0;
}

.toggle:checked ~ .twitter-heart-solid {
  animation: heart-animate 0.5s linear 0s;
}

/* scroll */

.dashboard-left-container::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border-radius: 10px;
}

.dashboard-left-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.dashboard-left-container::-webkit-scrollbar-thumb {
  background-color: #dcdeff5c;
  border-radius: 10px;
  z-index: -1;
  /* outline: 1px solid slategrey; */
}

::-webkit-scrollbar {
  width: 14px;
  height: 4px;
}

::-webkit-scrollbar-track {
  border: #141820 !important;
  background-color: #141820 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #222937 !important;
  border: 1px solid #222937 !important;
}

@keyframes heart-animate {
  25% {
    transform: scale(2);
    opacity: 0.3;
  }
  50% {
    transform: scale(2.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* 8-april */

.btn img {
  filter: invert(100%) sepia(1%) saturate(7478%) hue-rotate(349deg) brightness(108%) contrast(106%);
}

.filter-tab .btn img {
  filter: invert(75%) sepia(4%) saturate(3049%) hue-rotate(198deg) brightness(85%) contrast(75%);
}

a {
  display: block;
}

.button {
  padding: 2px;
  background: linear-gradient(to right, #43455f, #43455f);

  border-radius: 10px;
}

.modal-content {
  height: auto;
  border-radius: 20px;
  background-color: inherit;
  border: none;
}

.text_model {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.text2_model {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8e8f99;
}

.model_box1,
.model_box2 {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212342;
  /* overflow: hidden; */
  border-radius: 10px;
  width: 100%;
  min-height: 211px;
  max-height: 211px;
  flex-direction: column;
}

.box2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

/* grediant border */

.button:hover {
  background: linear-gradient(to right, #65cbcc, #617ad1);
  border-radius: 10px;
  color: #ffffff;
  font-size: 20px;
  padding: 2px;
  text-decoration: none;
  transition: none;
}

.button:hover .model_box2,
.button:hover .model_box1 {
  border: none;
}

/* list.html */

.btn-secondary {
  color: #fff;
  background-color: #66cccc;
  border-color: #66cccc;
  width: 100%;
  border-radius: 10px !important;
}

.dark {
  .text_rc {
    color: $lightFontDark;
  }
}

.light {
  .text_rc {
    color: $darkFontLight;
  }
}

.text_rc {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  /* identical to box height */
  display: flex;
  align-items: center;
}

.list_right {
  border: 1px dashed #8e8f99;
  border-radius: 15px;
}

.bt1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  /* White */
  color: #ffffff;
}

.bt2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /*line-height: 19px;*/
  display: flex;
  align-items: center;
  text-align: center;
  /* White */
  color: #ffffff;
}

.btl1 {
  color: #66cccc;
}

.btl1:hover {
  cursor: pointer;
}

/* -----------------------------------tag */

form.search .my-tagsinput {
  padding: 0.5rem 1rem;
}

.selectTemp form.search input {
  width: 280px !important;
}

form.search a.btn {
  min-height: 40px;
}

.my-tagsinput {
  border: 1px solid #525366;
  border-radius: 10px;
  box-shadow: none;
  /* padding: initial; */
  display: flex;
  flex-wrap: wrap;
  padding: 0.375rem 0.75rem;
  background: #525366;
}

.my-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: transparent;
  max-width: inherit;
  font-family: 'Roboto' !important;
  color: #fff;
}

.tag {
  background-color: #66cacc;
  margin: 0em 0.1em 0.2em 0.1em;
  padding: 0em 0em 0em 0.5em;
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
  cursor: auto;
}

.remove {
  margin-left: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  color: #212342;
  padding: 1px 7px;
  border-radius: 15px;
}

.text-secondary {
  color: #66cccc !important;
}

a.text-secondary {
  margin-right: 0.5rem;
}

/* checkbox */

.custom-control-label {
  margin-bottom: 0;
  color: #ddd;
  background-color: #212342;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  border: 1px solid #ddd;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #212342;
}

/* range-slider */

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */

input[type='range'] {
  width: 100%;
}

.range-slider {
  margin: 30px 0 30px 0;
}

.range-slider {
  width: 100%;
}

.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (73px));
  height: 20px;
  border-radius: 11px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
}

.range-slider__range::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(180deg, #666ccc 0%, #66cccc 100%);
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}

.range-slider__range::-webkit-slider-thumb:hover {
  background: #66cccc;
}

.range-slider__range:active::-webkit-slider-thumb {
  background: #66cccc;
}

.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
}

.range-slider__range::-moz-range-thumb:hover {
  background: #66cccc;
}

.range-slider__range:active::-moz-range-thumb {
  background: #66cccc;
}

.range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #66cccc;
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 60px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #2c3e50;
  padding: 5px 10px;
  margin-left: 8px;
  display: none;
}

.range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #2c3e50;
  border-bottom: 7px solid transparent;
  content: '';
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

.digits {
  margin-top: -12px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.digits span {
  font-size: large;
}
.span0 {
  margin-right: 10px;
}
.span100 {
  margin-left: -10px;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #60c4c8;
}
.height-149 {
  height: 149px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.font-24 {
  font-size: 24px;
  line-height: 28px;
  color: #fff;
}
.w-168 {
  width: 200px;
}
table th {
  color: #fff;
  font-weight: normal;

  width: 25%;
  padding-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
table td {
  vertical-align: top;
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bor-bottm {
  border-bottom: 1px solid #343761;
}
.font-14b {
  font-size: 14px;
  color: #8e8f99;
}
.font-12a {
  font-size: 12px;
  color: #8e8f99;
}
.font-12 {
  font-size: 12px;
  color: #fff;
}

.font-14a {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-size: 14px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
  width: 25px;
  height: 25px;
}
.custom-checkbox {
  padding-right: 5px;
}
.custom-control-label::after {
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-control-label::before {
  border: 1px solid #fff;
  width: 25px;
  height: 25px;
}
.custom-control-label.font-14b {
  font-size: 14px;
  color: #8e8f99;
  margin-left: 10px;
  margin-top: 4px;
}
.w-150 {
  width: 150px;
  text-align: center;
}
.modal h4 {
  font-family: 'Roboto', serif;
  color: #fff;
  text-align: center;
}
.custom-control-label {
  margin-left: 5px;
  margin-bottom: 5px;
}

.h4,
h4 {
  font-size: 1.5rem;
}
.btn_grey {
  background-color: #212342;
  border-radius: 30px;
  border-color: #999999;
}
.btn_grey:hover {
  background-color: #000222;
}
.img_111 {
  border-radius: 20px;
  min-width: 400px;
  max-height: 400px;
}

.border_right {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-right: 1px solid #525366;
}
.left-font {
  font-size: 30px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  /* identical to box height */

  /* White */

  color: #ffffff;
}
.mid_text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin-bottom: 3px;
  text-transform: uppercase;
}
.border_r {
  border-right: 1px solid #525366;
}
.txt_font {
  font-size: 13px;
  font-family: Roboto;
  font-style: normal;
  align-items: center;
  color: #66cccc;
  margin-bottom: 0px;
}
.txt_font_big {
  font-size: 24px;
}
.txt_font_big > span > span {
  padding-left: 5px;
}
.txt_font_big > span > span:first-child {
  padding-left: 0px;
}
.ul > ul,
li {
  display: inline;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 17px;
  word-spacing: 15px;
}
.activee {
  color: #66cccc;
  border-bottom: 1px solid #66cccc;
}
.tag1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */

  /* Grey 1 */

  color: #8e8f99;
}
.tag2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */

  /* White */

  color: #ffffff;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #fdfdfd;
}

.imgtg {
  width: 34px;
  height: 34px;
}
#tab-new .nav-link.font-14 {
  font-size: 18px !important;
  text-align: left;
  padding-left: 0px;
  /* padding-right: 19px; */
  margin-right: 10px;
}
#tab-new h6.font-14 {
  font-size: 16px;
  color: #8e8f99;
}
#tab-new .font-12 {
  font-size: 16px;
  color: #fff !important;
}
.dropdown-toggle::after {
  content: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  border: 1px solid transparent;

  &:focus {
    box-shadow: none;
  }
}

.dropdown-menu {
  background-color: #212342;
  color: #fff;
}

.dropdown-item {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
}

.dropdown-menu.show {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #c2c2c2;
  background-color: transparent;
}
#tab-new .nav-link {
  padding-left: 0px;
  font-size: 18px !important;
}
.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.dropdown-menu.show.r45 {
  // left: -50px !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #c2c2c2 !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

.btn-create {
  width: 110px;
}

.h-40 {
  height: 40px;
}

.dropdown-menu.show.r50 {
  // left: -7px !important;
}
.borderless td,
.borderless th {
  border: none;
}
#mycard {
  max-width: 100%;
}
#xyz {
  background: linear-gradient(180deg, #666ccc 0%, #66cccc 100%);
  color: #fff;
  border-color: #212342;
}
.hr-padding hr {
  margin-left: 0px;
  margin-right: 0px;
}
.menu::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.menu {
  position: relative;
}
.menu.dropdown-item::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 0.9rem;
  top: 18px;
}
.dropdown-menu.show.r0 {
  // left: 0px !important;
}
.close {
  float: right;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
  border: 1px solid #fff;
  width: 24px;
  height: 24px;
}
button.close {
  padding: 2px;
  background-color: transparent;
  border: 0;

  -webkit-appearance: none;
  border: 1px solid #fff;
  border-radius: 17px;
}
.close:focus,
.close:hover {
  color: #64cee8;
  text-decoration: none;
  opacity: 0.75;
  border: 1px solid #64cee8;
}
.model_box1 .custom-control-label::before {
  left: 40px;
  top: -4px;
}
.model_box1 .custom-control-label::after {
  left: 40px;
  top: -4px;
}
.model_box3 {
  min-height: 150px;
  max-height: 150px;
}
.box3 {
  font-size: 15px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #66cccc !important;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #212342;
  border-color: white !important;
  background-image: none !important;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: none !important;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: none;
}

.max-width-150 {
  min-width: 137px;
}
.w-100 {
  width: 100%;
}
/*wishlist page*/
#wishlistlist th:first-child {
  position: relative;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}
#wishlistlist table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #525366 !important;
}
#wishlistlist .table td,
#wishlistlist .table th {
  vertical-align: bottom;
  border-bottom: 1px solid #525366 !important;
  border-top: 0px;
  vertical-align: middle;
  min-width: 78px;
}
#wishlistlist #heart-container {
  left: 17px;
}
#wishlistlist td:first-child {
  width: 38px;
  /* min-width: 38px;
  max-width: 38px; */
  position: relative;
}

button.close {
  position: absolute;
  top: 11px;
  z-index: 1;
  right: 10px;
  height: 24px;
  width: 24px;
}
button.close1 {
  position: absolute;
  right: 55px;
  z-index: 1;
  height: 24px;
  width: 24px;
}

.top-21 {
  top: 21px;
}

.filternone {
  filter: none !important;
}
.border-none {
  border-radius: 9px !important;
  padding: 2px 8px 4px 8px;
  height: auto;
}
.margin-r {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.margin-r i {
  margin-right: 15px;
}
#wishlistlist #heart-container {
  top: 2rem;
}
#newnotification td {
  vertical-align: baseline !important;
}
#newnotification td:last-child {
  text-align: right;
  max-width: 64px;
}
.h5,
h5 {
  font-size: 16px !important;
}
.font-16 {
  font-size: 16px;
}
.range-slider {
  margin: 0px 0 30px 0;
}
.border-gradient {
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to left, #65cbcc, #617ad1);
}

.dark {
  .card-body {
    background-color: $jet;
  }
}
.light {
  .card-body {
    background-color: $cream;
  }
}
.card-body {
  padding: 15px;
  height: 156px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  min-width: 232px;
  max-width: 232px;
}
.filter-tab .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #212342;
}

.btn-default:hover {
  background: linear-gradient(180deg, #66cccc 0%, #666ccc 100%);
  color: #fff;
  border-color: #212342;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #000222;
  border-color: #000222;
}

.dark {
  .card:hover {
    box-shadow: 0 0 26px 0 rgba(255, 255, 255, 0.3);
  }
}
.light {
  .card {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);

    &:hover {
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

#xyz,
#abcd {
  width: 163px;
  height: 37px;
  /*text-transform: uppercase;*/
}
#newnotification button {
  width: 100px;
  height: 37px;
}
#newnotification .font-14 {
  font-size: 12px;
  text-align: center;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #c2c2c2;
  text-decoration: none;
  background-color: transparent;
}

.color-grey {
  font-size: 14px;
  color: #8e8f99;
}

.dark {
  .modal-body {
    background-color: $jet;
    box-shadow: 0 0 12px 0 #000000;
  }
}

.light {
  .modal-body {
    background-color: $pureWhite;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  }
}

.modal-body {
  cursor: default;
  border-radius: 20px;
  padding: 0 30px;
  max-height: 85vh;
  overflow-y: auto;
}

@media (max-width: 400px) {
  .modal-body {
    padding: 0 20px;
  }
}

#newnotification td:first-child {
  width: 51px !important;
  min-width: 59px !important;
  max-width: 90px !important;
}

/* Custom Input */

.custom-control-label {
  color: #ddd;
  margin-bottom: 5px;
}

// .create-nft .custom-control-label {
//   position: relative !important;
// }

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0 !important;
  display: block;
  pointer-events: none;
  content: '';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #212342;
  border: 1px solid #fff;
  width: 25px;
  height: 25px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #66cccc;
}

.custom-control-label::after {
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  left: 0 !important;
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* Responsive styles */

@media only screen and (max-width: 992px) {
  /* For mobile phones: */
  .model_box1,
  .model_box2 {
    width: 100%;
  }
  .model_box2 {
    margin-top: 0px;
  }

  .profile-left {
    position: absolute;
    left: calc(50% - 60px);
    bottom: -60px;
    width: 20%;
  }
  .err {
    padding-right: 0px !important;
  }
  .d-flex-wrap {
    flex-wrap: wrap;
  }
  .mt-res {
    margin-top: 10px;
  }
  #myModalb .mb-3 {
    margin-bottom: 0px !important;
  }
  #myModalb .model_box3 {
    min-height: 111px;
  }
  .pad-mob0 {
    padding: 0px !important;
  }
  .pad-15 {
    padding: 15px !important;
  }
  .pad-mob0 {
    margin-top: 20px !important;
  }
}
@media (max-width: 991px) {
  .right-top-browse > div {
    flex-wrap: wrap;
  }
  .border_right {
    border-right: 0px;
    border-bottom: 1px solid #525366;
  }
  .border_r {
    border-right: 0px;
    border-bottom: 1px solid #525366;
    margin-top: 10px;
  }
  .txt_font {
    margin-bottom: 10px;
  }
  .img_111 {
    min-width: 100%;
    margin-top: 10px;
  }

  .button {
    margin-top: 10px;
  }

  #wishlistlist #heart-container {
    top: 3rem;
  }
  #wishlistlist td {
    vertical-align: top !important;
  }
  #newnotification td {
    vertical-align: top !important;
  }
  #newnotification td:first-child,
  #wishlistlist td:first-child {
    min-width: 70px;
    max-width: 70px;
  }
  .table-responsive.text-white {
    font-size: 12px;
  }
  .card-body .table-responsive p {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .filter-tab button {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
  }
  .right-top-browse h4 {
    font-size: 1.3rem;
    margin-bottom: 0px;
  }
  .btn {
    font-size: 0.9rem;
  }
  // .card {
  //   min-width: 96% !important;
  //   max-width: 200px;
  // }
  .img_111 {
    min-width: 100%;
  }
  .profile-left {
    position: absolute;
    left: calc(50% - 60px);
    bottom: -60px;
    width: 16%;
  }
  #content .p-30 {
    padding: 0px;
  }
  .pad-left {
    padding-left: 0px !important;
    padding-top: 10px !important;
  }
  .margin-left {
    margin-left: 0px !important;
    padding-top: 0px !important;
  }
  .dropdown-menu.show {
    // left: -45px !important;
    box-shadow: 0 4px 8px 0 #00000033;
    border-radius: 20px;
  }
  .right-container hr {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mt-res {
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .modal-content {
    max-width: 98%;
    margin: 0 auto;
  }
}

@media (max-width: 460px) {
  .right-container .p-30 {
    padding: 15px 0px;
  }
  .right-container hr {
    margin-left: 0px;
    margin-right: 0px;
  }
  .right-top-browse .flex-nowrap {
    flex-wrap: wrap !important;
    width: 98%;
  }

  .modal h4 {
    margin-top: 30px;
  }
  .right-top-browse .flex-nowrap .btn {
    margin-left: 5px !important;
    margin-right: 0 !important;
    width: auto;
  }
  .right-top-browse .flex-nowrap1 .btn {
    margin-left: 3px !important;
    margin-right: 0 !important;
    width: auto;
  }
  .filter-tab button {
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    min-width: auto;
    font-size: 0.7rem;
  }
  .btn {
    font-size: 0.7rem;
  }
  .txt_font {
    margin-bottom: 10px;
  }
  .right-container .p-30 {
    padding: 21px 17px;
  }
}

/* Modal button mobile */
@media (max-width: 460px) {
  .modal-body .btn {
    max-width: 45%;
  }
}

/* Modal image style */
.bor-bottm img {
  border-radius: 0.5rem;
}

/* Profile Page styles */
.nav-tabs {
  border-bottom: 0px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus {
  color: #60c4c8;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #60c4c8;
}

.nav-tabs .nav-link {
  background-color: transparent;
}

.card-img-top {
  width: 232px;
  height: 232px;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
}

.class-img {
  width: 232px;
  height: 232px;
  object-fit: cover;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
}

.profile-image {
  background: #fff;
  border-radius: 100px;
  border: 1px solid #d1d1d1;
  width: 50px;
  height: 50px;
}

.border_50 {
  border-radius: 50%;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wp-85 {
  width: 85%;
}
.hw {
  height: 25px;
  width: 25px;
}
.position_relative {
  position: relative;
}
.rt_icon {
  position: absolute;
  right: 5px;
  top: 3px;
  padding: 4px;
  background-color: black;
  border-radius: 50%;
  transform: translate(-5px, -3px);
}
.w100p {
  max-width: 130px;
}
/* svg icons */
.SVGimage svg {
  height: 25px;
  width: 25px;
}

.profile-input button[editext='save-button'],
.profile-input button[editext='cancel-button'] {
  padding: 0;
}
