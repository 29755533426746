.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #66c2cc;
  font-size: 10px;
  z-index: 10000;
  margin: 490px auto;
  position: fixed;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  top: 0;
  left: 50%;
}
@media (min-width: 300px) {
  .loader {
    margin: 230px auto;
  }
}
.without-margin {
  margin: auto auto;
  margin-bottom: 50px;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.modal-backdrop {
  background-color: #000000 !important;

  &.show {
    opacity: 0.8 !important;
  }
}
