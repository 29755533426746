@import 'src/assets/styles/colors.scss';

.reseticon {
  width: 16px;
  margin-right: 6px;
  margin-left: -21px;
}
.filterbox {
  margin-left: 8px !important;
}

.dark {
  .filter-button {
    background-color: $greyTransparent;
    color: $grey;
  }

  .dropdown-menu {
    background-color: $carbon;
  }
  .dropdown-item {
    // color: $borderLight;
    color: $grey;
  }
}

.light {
  .filter-button {
    background-color: $cream;
    color: $darkGrey;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  }

  .dropdown-menu {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    background-color: $pureWhite;
  }
  .dropdown-item {
    color: $darkFontLight;
  }
}

.filter-button {
  border: none !important;
  margin: 0 5px;
  transition: all 0.2s ease-in-out;
  font-size: 15px;
  font-weight: 500;
  user-select: none;
  transition: all 0.2s ease-in-out;
  min-width: 88px;
  height: 33px;
  // padding: 0 28px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(84, 97, 123, 0.2);
  color: $grey;

  &.home {
    margin-right: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    font-size: 14px;
  }

  &.marketplace {
    margin-left: 20px;
    margin-right: 20px;
  }

  &:hover {
    filter: brightness(120%);
    background-color: rgba(84, 97, 123, 0.2) !important;
    color: rgba(255, 255, 255, 0.9) !important;
  }

  &.disabled {
    padding: 0 20px 0 28px;

    &:hover {
      filter: brightness(100%);
      // background-color: rgba(54, 119, 239, 0.2) !important;
      color: $grey !important;

      a {
        filter: brightness(100%);
        background-color: rgba(54, 119, 239, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.isActive {
    background-color: rgba(54, 119, 239, 0.2) !important;
    color: $highlightedBlueFonts !important;
  }
}

.dropdown-menu {
  padding: 16px 24px 0 24px;
  border-radius: 20px;
  min-width: auto !important;
}

.dropdown-item {
  font-size: 14px;
  font-weight: 500;

  margin-bottom: 16px;
}
.dropdown-item.active {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  .filter-label {
    width: calc(100% - 19px);
  }
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: $primaryFontsDark;
}

.show > .filter-button.dropdown-toggle {
  background: $blueTransparent;
  color: $blue;
}

.filter-reset-icon {
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.dropdown-toogle-item-expandable-arrow {
  transition: all 0.3s ease-in-out;
  width: 12px;
  margin-left: 10px;

  &.open {
    transform: rotate(180deg);
    color: $highlightedBlueFonts !important;
  }

  &:hover {
    filter: brightness(120%);
  }
}
