@import 'src/assets/styles/colors.scss';

/* Desktop */

.dark {
  .main-content {
    background: $black;
  }
}

.light {
  .main-content {
    background: $pureWhite;
  }
}

.appLayout {
}

.fixed-sidebar {
}

#content {
  width: 100%;
  color: #fff;
}

.oy-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

// .ox-auto {

// }

.right-container {
  cursor: default;
}

.right-container hr {
  margin-left: -15px;
  margin-right: -15px;
}

.right-container .p-30 {
  padding: 30px;
}

/* Mobile */
@media (max-width: 460px) {
  .right-container .p-30 {
    padding: 15px 0px;
  }

  .right-container hr {
    margin-left: -5px;
    margin-right: -5px;
  }
}

/* Item Detail Speicific Style */

.hr-padding hr {
  margin-left: 0px;
  margin-right: 0px;
}

.right-top-browse h4 {
  font-family: 'Montserrat', serif;
  white-space: nowrap;
}

.border_right {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-right: 1px solid #525366;
}

#tab-new .nav-link {
  padding-left: 0px;
  font-size: 18px !important;
}

#tab-new .nav-link .font-14 {
  font-size: 16px !important;
  text-align: left;
  padding-left: 0px;
  margin-right: 10px;
}

#tab-new h6.font-14 {
  font-size: 16px;
  color: #8e8f99;
}

#tab-new .font-12 {
  font-size: 16px;
  color: #fff !important;
}

/* Nav tabs styles */
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #fdfdfd;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #60c4c8;
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
}

/* Button Styles */
.btn {
  border-radius: 5rem;
  padding: 0.375rem 1rem;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  background: #000222;
}

.btn-default {
  background: linear-gradient(180deg, #666ccc 0%, #6cc 100%);
  border-color: #212342;
  color: #fff;
}

.btn-primary {
  background: none;
  background-clip: padding-box;
  border: 1px solid #66c0cc;
  position: relative;
}

.btn-primary:hover {
  background-color: #000222;
  border-color: #000222;
  color: #fff;
}

.btn img {
  filter: invert(100%) sepia(1%) saturate(7478%) hue-rotate(349deg) brightness(108%) contrast(106%);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #212342;
  color: #fff;
}

.btn-secondary {
  background-color: #6cc;
  border-color: #6cc;
  border-radius: 10px !important;
  color: #fff;
  width: 100%;
}

.btn_grey {
  background-color: #212342;
  border-color: #999;
  border-radius: 30px;
}

.btn_grey:hover {
  background-color: #000222;
}

.button {
  background: linear-gradient(to right, #ddd, #ddd);
  border-radius: 10px;
  padding: 2px;
}

.button:hover {
  background: linear-gradient(to right, #65cbcc, #617ad1);
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  padding: 2px;
  text-decoration: none;
  transition: none;
}

.button:hover .model_box2,
.button:hover .model_box1,
.borderless td,
.borderless th {
  border: none;
}

@media (max-width: 768px) {
  .btn {
    font-size: 0.9rem;
  }
  // #content {
  //   margin-left: auto;
  // }
}

@media (max-width: 460px) {
  .btn {
    font-size: 0.7rem;
  }
  // #content {
  //   margin-left: auto;
  // }
}

/* Top Bar Search*/
.search-img {
  left: 1rem;
  margin: 0.3rem 0;
  position: absolute;
}

.filter-tab button {
  border: 1px solid #525366;
  color: #9598c2;
  margin: 0.2rem;
}

button.select {
  border-color: #66c0cc;
  color: #fff;
}

button.close {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border: 1px solid #fff;
  border-radius: 17px;
  padding: 2px;
}

@media (max-width: 768px) {
  .filter-tab button {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
  }
}

@media (max-width: 460px) {
  .filter-tab button {
    font-size: 0.7rem;
    min-width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Dropdowm styles */
.dropdown-item {
  // color: #54617b;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: #c2c2c2;
  text-decoration: none;
}

.dropdown-menu {
  background-color: #212342;
  color: #fff;
}

.dropdown-menu.show {
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // left: -90px !important;
}

.dropdown-menu.show.r0 {
  left: 0 !important;
}

.dropdown-menu.show.r45 {
  left: -50px !important;
}

.menu {
  position: relative;
}

.menu.dropdown-item::after {
  position: fixed;
  right: 0.9rem;
  top: 18px;
  transform: rotate(-90deg);
}

.menu::after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: '';
  display: inline-block;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  width: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer,
.dropdown-toggle::after {
  content: none;
}

#newnotification.font-14 {
  color: #66cccc;
  font-size: 14px;
}
