button:disabled {
  cursor: not-allowed;
}

.file-upload {
  font-size: 14px;
  word-spacing: 0px;
}

.file-upload-container {
  flex-flow: column;
  text-align: center;
}

.validation-error {
  color: red;
  font-size: 12px;
}

.form-control {
  color: white;
}

.selected-nft-store {
  display: flex;
}

.taglist-nft-store-container {
  justify-content: flex-start;
}

.taglist-nft-store {
  cursor: pointer;
  margin-right: 12px;
}
