@import 'src/assets/styles/colors.scss';

.dark {
  .synchronize-wallet-back-icon {
    stroke: $darkFontDark;
    margin-right: 20px;
  }
  .synchronize-wallet-title-container,
  .synchronize-wallet-login-points,
  .synchronize-wallet-signup-text-container {
    color: $lightFontDark;
  }
  .synchronize-wallet-close-icon {
    fill: $grey;
  }
  .unactive {
    background-color: $carbon;
    color: $lightFontDark;
  }
  .synchronize-wallet-user-guest-description,
  .synchronize-wallet-login-description {
    color: $mediumFontDark;
  }
  .syncronize-wallet-user-guest-image {
    fill: $grey;
  }
  .synchronize-wallet-container {
    input {
      background-color: $charcoal;
      color: $grey;
    }
  }
  .synchronize-wallet-cancel-button {
    color: $grey;
    background-color: $greyTransparent;
  }
}

.light {
  .synchronize-wallet-back-icon {
    stroke: $lightFontLight;
    margin-right: 20px;
  }
  .synchronize-wallet-title-container,
  .synchronize-wallet-login-points,
  .synchronize-wallet-signup-text-container {
    color: $darkFontLight;
  }
  .synchronize-wallet-close-icon {
    fill: $darkGrey;
  }
  .unactive {
    background-color: $cream;
    color: $darkFontLight;
  }
  .synchronize-wallet-user-guest-description,
  .synchronize-wallet-login-description {
    color: $mediumFontLight;
  }
  .syncronize-wallet-user-guest-image {
    fill: $darkGrey;
  }
  .synchronize-wallet-container {
    input {
      background-color: $pureWhite;
      color: $borderLight;
    }
  }
  .synchronize-wallet-cancel-button {
    color: $darkGrey;
    background-color: $darkGreyTransparent;
  }
}

.synchronize-wallet-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 30px;
}

.synchronize-wallet-close-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    filter: brightness(130%);
  }
}

.synchronize-wallet-back-icon {
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    filter: brightness(130%);
  }
}

.synchronize-wallet-proceed-button {
  margin-top: 30px;
  margin-bottom: 30px;
  button {
    width: 100%;
    border-radius: 17px;
    background-color: $blue;
    color: $pureWhite;
    border: none;
    padding: 8px 0;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    &:hover {
      filter: brightness(130%);
    }
  }
}

.synchronize-wallet-user-guest-container {
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
}

.syncronize-wallet-user-guest-type {
  width: 200px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  row-gap: 20px;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    filter: brightness(130%);
  }
}

.synchronize-wallet-user-guest-description {
  margin-top: 10px;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 1.43;
}

.active {
  // GO: THIS !important IS AFFECTING OTHER CLASSNAME THAT HAS CLASSNAME 'active' INCLUDED. PLEASE AVOID USING !important. SASIKANT OR SHILPA PLEASE TAKE NOTE OF THIS.
  color: $blue;
  background: $blueTransparent;
  .syncronize-wallet-user-guest-image {
    fill: $blue;
  }
}

.synchronize-wallet-login-description {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 1.57;
  margin-bottom: 20px;
}

.synchronize-wallet-login-points {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 1.71;
  display: flex;
  column-gap: 8px;
  row-gap: 12px;
  margin-bottom: 20px;
}

.synchronize-wallet-email-input {
  margin-bottom: 20px;
  input {
    &.invalid {
      border-color: #e52e2e;
    }
  }
}

.synchronize-wallet-password-input {
  margin-bottom: 30px;
}

.synchronize-wallet-update-password-input {
  margin-bottom: 20px;

  input {
    &.invalid {
      border-color: #e52e2e;
    }
  }
}

.synchronize-wallet-login-button {
  padding: 8px 0;
  border-radius: 21px;
  background-image: $mainGradient;
  color: $pureWhite;
  width: 100%;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  border: none;
  margin-bottom: 20px;
  transition: all 0.25s ease-in-out;
  &:hover {
    filter: brightness(130%);
  }
}

.synchronize-wallet-cancel-button {
  padding: 8px 0;
  width: 100%;
  border-radius: 17px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  border: none;
  transition: all 0.25s ease-in-out;
  &:hover {
    filter: brightness(130%);
  }
}

.synchronize-wallet-signup-text-container {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  display: flex;
  margin-top: 30px;
  a {
    color: $blue;
    &:hover {
      text-decoration: underline;
      color: $blue;
    }
  }
}

.synchronize-wallet-signup-guest-text {
  margin-left: 5px;
}

.synchronize-wallet-signup-guest-text {
  margin-right: 5px;
}

.synchronize-wallet-title-container-heading {
  height: 24px;
  margin-bottom: 12px;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #c2c2c2;
}

.synchronize-wallet-title-container-subheading {
  height: 32px;
  margin-bottom: 12px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  text-align: left;
  color: #989898;
}

.synchronize-wallet-title-container-subcontainer {
  width: 414px;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  background-color: #0c0f13;
}
