@import 'src/assets/styles/colors.scss';

/* ---------------------------------------------------
    NAVBAR STYLE
----------------------------------------------------- */

.dark {
  .navbar-header-container,
  .navbar-navigation-container {
    background-color: $jet;
  }

  .navbar-header-container {
    border-bottom: 1px solid $borderCharcoal;
  }

  .navbar-theme-changer-container {
    background-color: $charcoal;
  }

  .navbar-notification-container {
    background-color: $charcoal;
  }

  .navbar-profile-container {
    background-color: $charcoal;
  }

  .navbar-username-container {
    color: $lightFontDark;
  }

  .navbar-address-container {
    color: $mediumFontDark;
  }
}

.light {
  .navbar-header-container,
  .navbar-navigation-container {
    background-color: $pureWhite;
  }

  .navbar-header-container {
    border-bottom: 1px solid $borderLight;
  }

  .navbar-theme-changer-container {
    background-color: $cream;
  }

  .navbar-notification-container {
    background-color: $cream;
  }

  .navbar-profile-container {
    background-color: $cream;
  }

  .navbar-username-container {
    color: $darkFontLight;
  }

  .navbar-address-container {
    color: $mediumFontLight;
  }
}

.navbar-main-container {
  cursor: default;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.navbar-header-container {
  display: flex;
  justify-content: space-between;
  padding: 14px 30px;
}

@media (max-width: 420px) {
  .navbar-header-container {
    padding: 14px 12px;
  }
}

.navbar-header-text {
  display: flex;
  align-items: center;
  background: linear-gradient(to left, #ff3d00, #9100ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-family: 'Montserrat';
  font-size: 21px;
  font-weight: 700;
  white-space: nowrap;
}

.navbar-visit-torum-button {
  cursor: pointer;
  height: 32px;
  width: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3677ef;
  // background-image: linear-gradient(to left, #2196f3, #682be6);
  background: rgba(54, 119, 239, 0.2) !important;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  margin-left: 16px;

  &:hover {
    filter: brightness(140%);
    transform: scale(1.05);
  }
}

.navbar-visit-torum-link {
  color: #3677ef;

  a {
    color: #3677ef;
  }

  &:hover {
    color: #3677ef;
  }
}

.navbar-theme-changer-container {
  cursor: pointer;
  border-radius: 14px;
  width: 37px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(120%);
  }
}

.navbar-navigation-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  overflow-x: scroll;
}

@media (max-width: 420px) {
  .navbar-navigation-container {
    padding: 10px 8px;
  }
}

.navbar-navigation-left-wrapper {
  display: flex;
}

.navbar-navigation-item-container {
  display: flex;
  align-items: center;
}

.series-navbar-navigation-item {
  border: none !important;
  margin: 0 5px;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  transition: all 0.2s ease-in-out;
  min-width: 88px;
  height: 33px;
  // padding: 0 28px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(84, 97, 123, 0.2);
  color: $grey;

  &.home {
    margin-right: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
  }

  &.marketplace {
    margin-left: 20px;
    margin-right: 20px;
  }

  &:hover {
    filter: brightness(120%);
    background-color: rgba(84, 97, 123, 0.2) !important;
    color: rgba(255, 255, 255, 0.9) !important;
  }

  &.disabled {
    padding: 0 20px 0 28px;

    &:hover {
      filter: brightness(100%);
      // background-color: rgba(54, 119, 239, 0.2) !important;
      color: $grey !important;

      a {
        filter: brightness(100%);
        background-color: rgba(54, 119, 239, 0.2) !important;
        color: $grey !important;
      }
    }
  }

  &.isActive {
    background-color: rgba(54, 119, 239, 0.2) !important;
    color: $highlightedBlueFonts !important;
  }
}

.navbar-navigation-item {
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  transition: all 0.2s ease-in-out;
  min-width: 88px;
  height: 33px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(84, 97, 123, 0.2);
  color: #54617b;
  padding: 8px 24px;

  &.home {
    margin-right: 20px;
  }

  &.marketplace {
    margin-left: 20px;
    margin-right: 20px;
  }

  &:hover {
    filter: brightness(120%);
    // background-color: rgba(54, 119, 239, 0.2) !important;
    color: rgba(255, 255, 255, 0.7) !important;

    a {
      filter: brightness(120%);
      background-color: rgba(54, 119, 239, 0.2) !important;
      color: rgba(255, 255, 255, 0.9) !important;
    }
  }

  &.disabled {
    padding: 8px 20px;

    &:hover {
      filter: brightness(100%);
      // background-color: rgba(54, 119, 239, 0.2) !important;
      color: #54617b !important;

      a {
        filter: brightness(100%);
        background-color: rgba(54, 119, 239, 0.2) !important;
        color: #54617b !important;
      }
    }
  }

  &.isActive {
    background-color: rgba(54, 119, 239, 0.2) !important;
    color: #3677ef !important;
  }
}

.navbar-navigation-lock-icon {
  margin-left: 6px;
}

.navbar-navigation-right-wrapper {
  display: flex;
  align-items: center;
}

.navbar-join-discord-button {
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 100px;
  background-image: linear-gradient(to right, #2a38de, #d25502);
  color: $pureWhite;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  margin-left: 20px;

  &:hover {
    filter: brightness(130%);
  }
}

.navbar-connect-wallet-container {
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 100px;
  background: linear-gradient(to left, #ff3d00, #9100ff);
  color: $pureWhite;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  margin-left: 20px;

  &:hover {
    filter: brightness(115%);
  }
}

@media (max-width: 600px) {
  .navbar-connect-wallet-container {
    padding: 8px 20px;
  }
}

.navbar-profile-container {
  display: flex;
  align-items: center;
  width: 184px;
  height: 49px;
  border-radius: 30px;
  padding: 4px 10px;
  margin-left: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(120%);
  }
}

.navbar-profile-image {
  width: 34px;
  height: 34px;
  border-radius: 100px;
}

.navbar-username-address-container {
  margin-left: 10px;
  line-height: 1.15;
}

.navbar-username-container {
  font-size: 14px;
  font-weight: 500;
}

.navbar-address-container {
  font-size: 14px;
  font-weight: 400;
}

.navbar-header-right-container {
  display: flex;
  align-items: center;
}

.navbar-logout-container {
  cursor: pointer;
  background-color: rgba(84, 97, 123, 0.2);
  color: #54617b;
  min-width: 106px;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 22px;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 500;
  margin-left: 12px;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(120%);
    color: #fff;
  }
}

// SOCIAL MEDIA LINK

.navbar-header-social-media-link-container {
  display: flex;
  justify-content: center;
}

.navbar-header-social-media-link {
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
}

.navbar-header-social-media-icon {
  cursor: pointer;
  width: 32px;
  height: 32px;

  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(150%);
    transform: scale(1.1);
  }
}

.dropdown-toogle-item-expandable-arrow {
  transition: all 0.3s ease-in-out;
  width: 12px;
  margin-left: 10px;

  &.open {
    transform: rotate(180deg);
    color: #3677ef !important;
  }

  &:hover {
    filter: brightness(120%);
  }
}
